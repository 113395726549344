import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import Sites from "@/views/Sites";
import SurveyForm from "@/views/Screener.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "SurveyForm",
    component: SurveyForm,
    meta: { auth: false },
  },
  {
    path: "/sites",
    name: "Sites",
    component: Sites,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
