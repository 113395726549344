<template>
  <v-card elevation="5" class="pb-4 px-3">
    <v-card-title>
      <p class="font-weight-bold mb-0" v-html="site.site_location.name"></p>
    </v-card-title>
    <v-card-subtitle v-if="!site.site_location.is_virtual">
      <p class="mb-0">{{ site.distance | numberFormat }} {{ genericMessages.miles }}</p>
    </v-card-subtitle>
    <!--  if site is virtual, display virtual site address  -->
    <v-card-text v-if="site.site_location.is_virtual">
      <p
          class="font-weight-bold"
          v-html="site.site_location.location.street_address"
      ></p>
    </v-card-text>
    <v-card-text v-else>
      <p
        class="font-weight-bold mb-0"
        v-html="site.site_location.location.street_address"
      ></p>
      <p
        class="font-weight-bold mb-0"
        v-html="site.site_location.location.unit_number"
      ></p>
      <p class="font-weight-bold mb-0">
        {{ site.site_location.location.city }},
        {{ site.site_location.location.state }}
        {{ site.site_location.location.zip_code }}
      </p>
      <p class="font-weight-bold">{{ site.site_location.country_name }}</p>
      <p>
        <a :href="site.directionsHref" target="_blank">{{ genericMessages.directions }}</a>
      </p>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="orangeBrown"
        rounded
        class="pa-7 white--text"
        @click="$emit('siteSelected')"
      >
        {{ genericMessages.select }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "TrialSiteListing",
  props: {
    site: {
      type: Object,
    },
  },
  computed: {
    ...mapFields("portal", ["genericMessages"]),
  },
  filters: {
    numberFormat: function (distance) {
      let formattedDistance = distance;
      if (formattedDistance > 0) {
        formattedDistance = formattedDistance.toFixed(1);
      }

      return formattedDistance;
    },
  },
};
</script>

<style scoped></style>
