<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="12">
        <p
          class="contact-terms"
          v-html="screenerMessages.completed.contactFormMessage"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "Confirmation",
  data() {
    return {
      contactFormProperty: "completed_contact_form",
    };
  },
  computed: {
    ...mapState("surveySubmissions", ["surveyMessages"]),
    ...mapFields("portal", ["screenerMessages"]),
  },
};
</script>
