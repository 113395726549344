<template>
    <v-container v-if="loaded">
      <v-row>
        <v-col cols="12" class="px-0">
          <v-toolbar dense max-height="50px" elevation="0">
            <v-row>
              <v-col cols="12" class="px-0">
                <!--
                  This "button" is not visible, it's just used as a spacer for consistency with screener pages which have a back button.
                -->
                <v-btn
                  plain
                  disabled
                  class="back-button"
                />
                <v-progress-linear
                  background-color="#F4EDE6"
                  color="orangeBrown"
                  height="7"
                  rounded
                  value="1"
                />
              </v-col>
            </v-row>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="px-0 pb-0">
          <p
            class="acceptance-title"
            v-html="screenerMessages.acceptance.contactFormMessage"
          />
        </v-col>
      </v-row>
      <v-form
        v-if="genericMessages"
        v-model="formValid"
        @submit.prevent="submit"
      >
        <v-row>
          <v-col cols="12" class="px-0">
            <v-checkbox
              v-model="acceptanceValue"
              :rules="$validation.requiredCheckbox(genericMessages.required_field)"
            >
              <template v-slot:label>
                <div
                  class="acceptance-terms"
                  v-html="screenerMessages.acceptance.consentMessage"
                />
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="px-0">
            <next-button :isDisabled="!formValid" />
          </v-col>
        </v-row>
      </v-form>
    </v-container>
</template>

<script>
import NextButton from "@/components/NextButton.vue";
import {mapActions} from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "Acceptance",
  components: { NextButton },
  data() {
    return {
      loaded: false,
      formValid: false,
      acceptanceValue: null,
      isSubmitting: false,
    };
  },
  computed: {
    ...mapFields("portal", ["screener", "screenerMessages", "genericMessages",]),
  },
  watch: {
    'screener.isActive': {
      handler() {
        if (this.screener.isActive) {
          this.loaded = true;
        } else {
          this.setRenderedView("contactForm");
        }
      },
    },
  },
  methods: {
    ...mapActions("portal", ["next", "setRenderedView"]),
    submit() {
      if (this.isSubmitting) return;
      this.isSubmitting = true;
      this.next({tt: this.screener.entryId, options: [{id: "acceptance", value: true}]});
      this.isSubmitting = false;
      this.setRenderedView("next");
    },
  },
  mounted() {
    document
      .getElementById("trialblazer")
      ?.scrollIntoView({ behavior: "smooth" });
    this.loaded = !!this.screener.isActive;
  },
};
</script>

<style lang="scss">
.acceptance-terms {
  p:last-child {
    margin-bottom: 0;
  }
}
</style>
