import contact from "./contact/index.js";
import portal from "./portal/index.js";
import sites from "./sites/index";
import surveySubmissions from "@/store/modules/survey_submissions/index";

export default {
  contact,
  portal,
  sites,
  surveySubmissions,
};
