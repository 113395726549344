<template>
  <v-btn
    class="white--text px-9 py-6 mt-2 font-weight-bold"
    color="orangeBrown"
    rounded
    type="submit"
    :disabled="isDisabled"
  >{{ genericMessages.next }}</v-btn>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "NextButton",
  data() {
    return {
      isLoading: true,
    };
  },
  props: {
    isDisabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapFields("portal", ["genericMessages"]),
  },
};
</script>

<style scoped></style>
