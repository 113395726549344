export const CLEAR_SITE_LOCATIONS = "CLEAR_SITE_LOCATIONS";
export const DECREMENT_PROGRESS_BAR_VALUE = "DECREMENT_PROGRESS_BAR_VALUE";
export const INCREMENT_PROGRESS_BAR_VALUE = "INCREMENT_PROGRESS_BAR_VALUE";
export const SET_CONTACT_ID = "SET_CONTACT_ID";
export const SET_ERROR = "SET_ERROR";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_MAP_MARKERS = "SET_MAP_MARKERS";
export const SET_PAGE = "SET_PAGE";
export const SET_PROGRESS_BAR_MAX = "SET_PROGRESS_BAR_MAX";
export const SNAPSHOT_SURVEY_PAGE_HISTORY = "SNAPSHOT_SURVEY_PAGE_HISTORY";
export const POP_SURVEY_PAGE_HISTORY = "POP_SURVEY_PAGE_HISTORY";
export const SANITIZE_SURVEY_PAGE_HISTORY = "SANITIZE_SURVEY_PAGE_HISTORY";
export const SET_RENDERED_VIEW = "SET_RENDERED_VIEW";
export const SET_SCREENER = "SET_SCREENER";
export const SET_SITE_LOCATIONS = "SET_SITE_LOCATIONS";
export const SET_TRANSITION_TO = "SET_TRANSITION_TO";
export const SET_ZIP_LAT_LNG = "SET_ZIP_LAT_LNG";
