import {
  CLEAR_SITE_LOCATIONS,
  SET_ERROR,
  SET_MAP_MARKERS,
  SET_SITE_LOCATIONS,
  SET_ZIP_LAT_LNG,
} from "@/store/mutation-types";
import Vue from "vue";
import { getField, updateField } from "vuex-map-fields";

const generateDirectionsHref = (
  originZipCode,
  destinationLatitude,
  destinationLongitude
) => {
  return `https://www.google.com/maps/dir/?api=1&origin=${originZipCode}&destination=${destinationLatitude},${destinationLongitude}`;
};
export default {
  namespaced: true,
  state: {
    errorMsg: "",
    mapMarkers: [],
    siteLocations: [],
    zipLat: 0,
    zipLng: 0,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    [CLEAR_SITE_LOCATIONS](state) {
      state.siteLocations = [];
    },
    [SET_MAP_MARKERS](state, { data, zipcode }) {
      let markers = [];
      data.forEach((site) => {
        let marker = {
          address: site.site_location.location,
          directionsHref: generateDirectionsHref(
            zipcode,
            site.site_location.latitude,
            site.site_location.longitude
          ),
          id: site.site_location.id,
          engagementId: site.engagement_id,
          position: {
            lat: parseFloat(site.site_location.latitude),
            lng: parseFloat(site.site_location.longitude),
          },
          title: site.site_location.name,
        };
        markers.push(marker);
      });

      state.mapMarkers = markers;
    },
    [SET_SITE_LOCATIONS](state, { data, zipcode }) {
      data.forEach((site) => {
        site.directionsHref = generateDirectionsHref(
          zipcode,
          site.site_location.latitude,
          site.site_location.longitude
        );
        state.siteLocations.push(site);
      });
    },
    [SET_ERROR](state, message) {
      state.errorMsg = message;
    },
    [SET_ZIP_LAT_LNG](state, data) {
      state.zipLat = parseFloat(data[0]["zip_lat"]);
      state.zipLng = parseFloat(data[0]["zip_long"]);
    },
  },
  actions: {
    getSiteLocations({ commit }, parts) {
      let uriParts = ["api", "participant-site-locations", parts[0], parts[1], parts[2]];
      commit(SET_ERROR, "");
      commit(CLEAR_SITE_LOCATIONS);

      return Vue.axios
        .get(uriParts.join("/"))
        .then(({ data }) => {
          if (data.engagementSiteLocations.length) {
            commit(SET_MAP_MARKERS, { data: data.engagementSiteLocations, zipcode: parts[1] });
            commit(SET_SITE_LOCATIONS, { data: data.engagementSiteLocations, zipcode: parts[1] });
            commit(SET_ZIP_LAT_LNG, data.engagementSiteLocations);
          } else {
            commit(SET_ZIP_LAT_LNG, [{ zip_lat: 0, zip_lng: 0 }]);
          }

          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);

          return false;
        });
    },
  },
};
