import {
  DECREMENT_PROGRESS_BAR_VALUE,
  INCREMENT_PROGRESS_BAR_VALUE,
  SET_CONTACT_ID,
  SET_PAGE,
  SET_PROGRESS_BAR_MAX,
  SET_RENDERED_VIEW,
  SET_SCREENER,
  SET_TRANSITION_TO,
} from "@/store/mutation-types";
import {getField, updateField} from "vuex-map-fields";
import Vue from "vue";

export default {
  namespaced: true,
  actions: {
    decrementProgressBar({commit}) {
      commit(DECREMENT_PROGRESS_BAR_VALUE);
    },
    incrementProgressBar({commit}) {
      commit(INCREMENT_PROGRESS_BAR_VALUE);
    },
    next({commit, rootState, state}, data) {
      const url = "/api/portal/next/";
      let requestBody = {
        "ci": state.ci,
        "mcu": rootState.mediaChannelUrl,
        "op": data.options,
        "si": state.screenerId,
        "tt": data.tt
      };
      commit("addSurveyHistory", data);

      return Vue.axios
        .post(url, requestBody)
        .then((response) => {
          let result = false;
          if (response.status === 200) {
            commit(SET_PAGE, response.data);
            if (response.data.questions !== undefined) {
              commit(INCREMENT_PROGRESS_BAR_VALUE);
            }

            result = true;
          }

          return Promise.resolve([result]);
        })
        .catch(() => {
          return Promise.resolve([false]);
        });
    },
    setRenderedView({commit}, flowState) {
      let view = "ContactForm";
      switch (flowState) {
        case "acceptance":
          view = "Acceptance";
          break;
        case "next":
          view = "Screener";
          break;
        case "confirmation":
          view = "Confirmation";
          break;
        case "contactForm":
          view = "ContactForm";
          break;
        default:
          view = "ContactForm";
      }
      commit(SET_RENDERED_VIEW, view);
    },
    setScreenerId({state}, screenerId) {
      state.screenerId = screenerId;
    },
    setTransitionTo({commit}, transitionTo) {
      commit(SET_TRANSITION_TO, transitionTo);
    },
    start({commit, state}) {
      const url = "/api/portal/start/" + state.screenerId;
      commit("clearSurveyHistory");
      return Vue.axios
        .get(url)
        .then((response) => {
          let result = false;
          if (response.status === 200) {
            const screener = response.data;
            if (screener !== null) {
              commit(SET_SCREENER, screener);
              commit(SET_PROGRESS_BAR_MAX, 10);
              commit(SET_CONTACT_ID, screener.ci);
              result = true;
            }
          }

          return Promise.resolve([result]);
        })
        .catch(() => {
          return Promise.resolve([false]);
        });
    },
    back({commit, state}) {
      commit(DECREMENT_PROGRESS_BAR_VALUE);
      if (state.surveyHistory.length) {
        const survey = state.surveyHistory[state.surveyHistory.length - 1];
        commit(SET_PAGE, {
          questions: survey.questions,
          preset: survey.options,
        });
      }
      commit("removeSurveyHistory");
    },
  },
  getters: {
    getField,
    getPage(state) {
      return state.page;
    },
    getContactId(state) {
      return state.ci;
    },
    getProgressBarData: (state) => {
      return {
        value: Math.floor(
          (state.progressBarValue / state.progressBarMax) * 100
        ),
      };
    },
    getRenderedView(state) {
      return state.renderedView;
    },
    getScreenerId(state) {
      return state.screenerId;
    },
    getTransitionTo(state) {
      return state.transitionTo;
    },
    getSurveyHistory: state => state.surveyHistory,
  },
  mutations: {
    updateField,
    [SET_CONTACT_ID](state, contactId) {
      state.ci = contactId;
    },
    [SET_PROGRESS_BAR_MAX](state, max) {
      state.progressBarMax = max;
    },
    [DECREMENT_PROGRESS_BAR_VALUE](state) {
      state.progressBarValue--;
    },
    [INCREMENT_PROGRESS_BAR_VALUE](state) {
      state.progressBarValue++;
    },
    [SET_PAGE](state, page) {
      let filteredQuestions = [];

      page.questions?.forEach((question, index) => {
        let currentQuestion = {};
        switch (question.type) {
          case "dob":
            currentQuestion = {
              ...question,
              component: "Birthdate",
              id: question.options[0].tt,
            }
            state.transitionTo = question.options[0].tt;
            break;
          case "single":
            currentQuestion = {
              ...question,
              component: "Select",
              id: "question-" + index,
            }
            break;
          case "multiple":
            currentQuestion = {
              ...question,
              component: "MultiSelect",
              id: "question-" + index,
            }
        }
        filteredQuestions.push(currentQuestion);
      });
      page.questions = filteredQuestions;
      state.page = page;

      if (page.isQualified === true) {
        state.renderedView = "Sites";
      } else if (page.isQualified === false) {
        state.renderedView = "ContactForm";
      }
    },
    [SET_RENDERED_VIEW](state, view) {
      state.renderedView = view;
    },
    [SET_SCREENER](state, screener) {
      state.ci = screener.ci;
      state.genericMessages = screener.generic_messages;
      state.screener = {
        isActive: screener.is_active,
        entryId: screener.tt,
      };
      state.screenerMessages = {
        acceptance: {
          contactFormMessage: screener.acceptance_contact_form_message,
          consentMessage: screener.acceptance_consent_message,
        },
        completed: {
          contactFormMessage: screener.completed_contact_form_message,
          consentMessage: screener.completed_consent_message,
        },
        notActive: {
          contactFormMessage: screener.not_active_contact_form_message,
          consentMessage: screener.not_active_consent_message,
        },
        notQualified: {
          contactFormMessage: screener.not_qualified_contact_form_message,
          consentMessage: screener.not_qualified_consent_message,
        },
        noTrialSites: {
          contactFormMessage: screener.no_trial_sites_contact_form_message,
          consentMessage: screener.no_trial_sites_consent_message,
        },
        qualified: {
          contactFormMessage: screener.qualified_contact_form_message,
          consentMessage: screener.qualified_consent_message,
        },
        trialSiteSelected: {
          contactFormMessage: screener.trial_site_selected_contact_form_message,
          consentMessage: screener.trial_site_selected_consent_message,
        }
      };
      state.styles = screener.styles;
    },
    [SET_TRANSITION_TO](state, transitionTo) {
      state.transitionTo = transitionTo;
    },
    addSurveyHistory: (state, value) => {
      state.surveyHistory.push(value);
    },
    removeSurveyHistory: (state) => {
      state.surveyHistory.pop();
    },
    clearSurveyHistory: (state) => {
      state.surveyHistory = [];
      state.page = {
        questions: [],
        preset: [],
      };
      state.progressBarValue = 0;
    },
  },
  state: {
    errorMsg: "",
    ci: "",
    page: {
      questions: [],
      preset: [],
    },
    surveyHistory: [],
    progressBarValue: 0,
    renderedView: "Screener",
    styles: {},
    genericMessages: {},
    screener: {},
    screenerId: "",
    screenerMessages: {
      acceptance: {
        contactFormMessage: "",
        consentMessage: "",
      },
      completed: {
        contactFormMessage: "",
        consentMessage: "",
      },
      notActive: {
        contactFormMessage: "",
        consentMessage: "",
      },
      notQualified: {
        contactFormMessage: "",
        consentMessage: "",
      },
      noTrialSites: {
        contactFormMessage: "",
        consentMessage: "",
      },
      qualified: {
        contactFormMessage: "",
        consentMessage: "",
      },
      trialSiteSelected: {
        contactFormMessage: "",
        consentMessage: "",
      }
    },
    transitionTo: "",
    mediaChannelUrl: "",
  },
};
