<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <p class="question-label" v-html="title"></p>
        <p v-html="helperText"></p>
      </v-col>
    </v-row>
    <v-text-field
      v-show="false"
      :value="value"
      :rules="$validation.required"
    />
    <v-row v-for="option in options" :key="option.value">
      <v-col cols="12" sm="12">
        <v-btn
          rounded
          :color="value === option.value ? 'darkBlue' : 'orangeBrown'"
          @click="updateValue(option)"
          :class="[
            'white--text option-select pa-4',
            { selected: value === option.value },
          ]"
          width="100%"
        >
          <v-icon
            v-if="value === option.value"
            left
          >
            mdi-check-bold
          </v-icon>
          <span class="text-wrap">{{ option.label }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Select",
  data() {
    return {
      value: null,
    };
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    helperText: {
      type: String,
      required: false,
      default: null,
    },
    preset: {
      type: Array,
      required: false,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.setDefault();
      },
    },
  },
  methods: {
    ...mapActions("portal", ["setTransitionTo"]),
    updateValue(option) {
      this.value = option.value;
      this.setTransitionTo(option.tt);
      this.$emit("selectOption", {
        id: this.id,
        selected: [{id: option.id, value: option.value}],
      });
    },
    setDefault() {
      this.value = null;

      // If the user has already selected a value, use that value
      this.preset?.forEach(preset => {
        const option = this.options?.find(option => option.id === preset.id) || {}
        if (option.value === preset.value) {
          this.updateValue(option);
        }
      });
    },
  },
  mounted() {
    this.setDefault();
  },
};
</script>

<style scoped></style>
