import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        darkBlue: "#001A6F",
        orangeBrown: "#AD3410",
        error: "#ad3410",
      },
    },
  },
};

export default new Vuetify(opts);
