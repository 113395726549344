<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="px-0">
        <p v-html="screenerMessages.qualified.contactFormMessage"></p>
        <p v-html="genericMessages.site_select_title"></p>
        <v-form
          v-model="formValid"
          @submit.prevent="submit"
        >
          <v-container>
            <v-row>
              <v-col md="3" cols="6" class="pl-0 pb-0">
                <v-text-field
                  :label="genericMessages.zip_code"
                  outlined
                  autofocus
                  v-model="zipcode"
                  :hint="genericMessages.invalid_zipcode"
                  :rules="$validation.zipCode(genericMessages.invalid_zipcode)"
                />
              </v-col>
              <v-col md="3" cols="6" class="pl-0 mb-2">
                <v-btn
                  color="orangeBrown"
                  rounded
                  class="pa-7 white--text"
                  type="submit"
                  :disabled="!formValid || submitting"
                  :loading="submitting"
                >
                  {{ genericMessages.search_button }}
                </v-btn>
              </v-col>
              <v-col md="6" cols="12" class="no-selection">
                <a @click="noSelection">{{ genericMessages.continue_without_selecting_a_location }}</a>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <p v-if="noSites">
          {{ genericMessages.zero_locations_found }}
        </p>
        <p v-else-if="zipcodeError" class="zip-code error--text">
          {{ genericMessages.missing_zipcode }}
        </p>
        <template v-if="showMap">
          <v-container id="site-locations" class="pa-0">
            <p v-if="hasSiteLocations">
              {{ genericMessages.site_engagement_prefix }}
              {{ engagementName }}
              {{ genericMessages.site_engagement_affix }}
            </p>
            <p class="site-locations mb-2">
              {{ siteLocations?.length || 0 }} {{ genericMessages.locations }}
            </p>
            <v-row>
              <v-col cols="12" md="6" class="site-locations-list px-2">
                <v-row v-for="(site, index) in siteLocations" :key="index">
                  <v-col cols="12">
                    <trial-site-listing
                      :site="site"
                      @siteSelected="selectSite(site, index)"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="6" class="site-locations-map py-0">
                <GmapMap :center="mapCenter" :zoom="7" map-type-id="terrain">
                  <gmap-info-window
                    :options="infoOptions"
                    :position="infoWindowPos"
                    :opened="infoWinOpen"
                    @closeclick="infoWinOpen = false"
                    class="info-window"
                  />
                  <GmapMarker
                    :key="index"
                    v-for="(marker, index) in mapMarkers"
                    :position="marker.position"
                    :title="marker.title"
                    :clickable="true"
                    @click="toggleInfoWindow(marker, index)"
                    color="#0000FF"
                  />
                </GmapMap>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-col>
    </v-row>

    <v-dialog
      v-model="siteSelected"
      persistent
      width="500"
      max-width="90%"
    >
      <v-card class="pa-4">
        <v-card-title>
          {{ genericMessages.selected_the_trial_site }} 
        </v-card-title>
        <v-card-text>
          <p v-html="infoOptions.content" class="pt-2 mb-0"></p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="orangeBrown"
            class="back-button"
            @click="backButton"
          >
            <v-icon>
              mdi-arrow-left-thick
            </v-icon>
           {{ genericMessages.back }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="showConfirmBtn"
            color="orangeBrown"
            rounded
            class="pa-7 white--text"
            @click="confirmSite()"
          >
            {{ genericMessages.confirm }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
.info-window {
  margin: 5px !important;
  padding: 5px !important;
}
.no-selection {
  padding-right: 0;
  margin-top: 16px;
  @media (max-width: 960px) {
    padding-left: 0;
    margin-top: 0;
  }
}
.site-locations-list {
  max-height: 700px;
  overflow: hidden auto;
  @media (max-width: 960px) {
    max-height: unset;
    overflow: unset;
  }
}
.site-locations-map {
  max-height: 700px;
  @media (max-width: 960px) {
    display: none;
  }
}
.vue-map-container {
  width: 100%;
  height: 100%;
}
</style>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import TrialSiteListing from "@/components/TrialSiteListing.vue";

export default {
  name: "Sites",
  components: {
    TrialSiteListing,
  },
  data() {
    return {
      formValid: false,
      currentMarkerIndex: null,
      infoOptions: {
        content: "",
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      infoWindowPos: null,
      infoWinOpen: false,
      mapCenter: {
        lat: 0,
        lng: 0,
      },
      noSites: false,
      showMap: false,
      siteSelected: false,
      contactFormProperty: "trial_site_selected_contact_form",
      consentProperty: "trial_site_selected_consent",
      zipcodeError: false,
      submitting: false,
      dialog: false,
      showConfirmBtn: true,
    };
  },
  computed: {
    ...mapFields("sites", [
      "errorMsg",
      "mapMarkers",
      "siteLocations",
      "zipLat",
      "zipLng",
    ]),
    ...mapFields("contact", ["zipcode"]),
    ...mapFields("portal", ["screenerMessages", "genericMessages"]),
    ...mapGetters("portal", {
      contactId: "getContactId",
      page: "getPage",
    }),
    // TODO: Cleanup Cruft Below
    ...mapState("surveySubmissions", ["surveyMessages"]),
    ...mapGetters("systemLanguages", ["languageTargets"]),
    hasSiteLocations() {
      return !!this.siteLocations?.length;
    },
    engagementName() {
      return this.hasSiteLocations ? this.siteLocations[0]?.engagement_name : "";
    },
    isQualified() {
      return this.page.isQualified;
    },
  },
  methods: {
    ...mapActions("sites", ["getSiteLocations"]),
    ...mapActions("contact", ["setZipcode", "setSelectedSite", "setEngagement"]),
    ...mapActions("portal", ["setRenderedView"]),
    confirmSite() {
      this.setSelectedSite(this.mapMarkers[this.currentMarkerIndex].id);
      this.setEngagement(this.mapMarkers[this.currentMarkerIndex].engagementId);
      this.setRenderedView("contactForm");
    },
    noSelection() {
      this.setRenderedView("contactForm");
    },
    backButton() {
      this.siteSelected = false;
    },
    submit() {
      this.zipcodeError = false;
      this.noSites = false;
      this.showMap = false;
      this.siteSelected = false;
      this.submitting = true;

      // If the map is currently being shown, hide it.
      this.setZipcode(this.zipcode).then((zipcode) => {
        if (zipcode) {
          this.getSiteLocations([this.contactId, zipcode, this.page.engagements]).then(() => {
            this.mapCenter = {
              lat: this.zipLat,
              lng: this.zipLng,
            };
            if (this.zipLat !== 0) {
              this.showMap = true;
              this.noSites = false;
            } else {
              this.showMap = false;
              this.noSites = true;
            }
          });
        } else {
          this.zipcodeError = true;
        }
        this.submitting = false;
      });
    },
    toggleInfoWindow: function (marker, index) {
      this.showConfirmBtn = true;
      this.infoWindowPos = marker.position;
      let contentParts = [
        "<b>" + (marker.title ? marker.title : marker.address["name"]) + "</b>",
        marker.address["street_address"],
        marker.address["city"] +
          ", " +
          marker.address["state"] +
          " " +
          marker.address["zip_code"],
        "<br><a href='" +
          marker.directionsHref +
          "' target='_blank'>" +
          this.genericMessages.directions +
          "</a>",
      ];
      if (marker.address.is_virtual) {
        this.showConfirmBtn = false;
        contentParts = [
          "<b>" + (marker.title ? marker.title : marker.address["name"]) + "</b>",
          marker.address["street_address"],
          marker.address.description,
        ];
      }
      this.infoOptions.content = contentParts.join("<br>");
      this.siteSelected = true;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMarkerIndex === index) {
        this.infoWinOpen = !this.infoWinOpen;
      }

      //if different marker set info window to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMarkerIndex = index;
      }
    },
    async selectSite(site, index) {
      this.zipLat = site.site_location.latitude;
      this.zipLng = site.site_location.longitude;
      const marker = {
        address: {
          name: site.site_location.name,
          street_address: site.site_location.location.street_address,
          city: site.site_location.location.city,
          state: site.site_location.location.state,
          zip_code: site.site_location.location.zip_code,
          is_virtual: site.site_location.is_virtual,
          description: site.site_location.description,
        },
        directionsHref: site.directionsHref,
      };
      await this.toggleInfoWindow(marker, index);

      //scroll back up to site locations container where confirm button is located once siteSelected is set to true in infoWindow
      document
        .getElementById("trialblazer")
        ?.scrollIntoView({ behavior: "smooth" });
    },
  },
  mounted() {
    document
      .getElementById("trialblazer")
      ?.scrollIntoView({ behavior: "smooth" });
  },
};
</script>

<style>
.gm-style-iw-a {
  display: none;
}
.gm-style-iw {
  padding: 15px !important;
}
.gm-ui-hover-effect {
  top: 0 !important;
  right: 0 !important;
}
</style>