import axios from "axios";
import compact from "lodash/compact";
import map from "lodash/map";

const navigatorLanguages = [...navigator.languages];
navigatorLanguages.forEach((language, index) => {
  const semicolonIndex = language.indexOf(";");
  if (semicolonIndex !== -1) {
    navigatorLanguages[index] = language.substring(0, semicolonIndex);
  }
});

const domLanguage = document
  .querySelector("html")
  .getAttribute("lang");

const acceptLanguages = [
  ...new Set(
    compact(
      map([
        ...navigatorLanguages,
        navigator.language,
        domLanguage
      ])
    )
  )
].toString();

export const getBaseUrl = () => {
  return process.env.VUE_APP_BASE_API_URL;
};

export const getDownloadUrl = (filename) => {
  return [window.location.origin, "downloads", filename].join("/");
};

export const apiClient = axios.create({
  baseURL: getBaseUrl(),
  withCredentials: false,
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
    "Accept-Language": acceptLanguages,
  },
});

export default apiClient;
