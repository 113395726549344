import Vue from "vue";
import GMapVue from "gmap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueAxios from "vue-axios";
import axios from "@/http/client";
import VueMask from "v-mask";
import "@/plugins/date";
import validation from "./plugins/validation";
import vuetify from "./plugins/vuetify";

let tb = document.querySelector("#trialblazer");

Vue.config.productionTip = false;

// Configure gMapVue
Vue.use(GMapVue, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: "places",
  },
  installComponents: true,
  dynamicLoad: false,
});

Vue.use(validation);
Vue.use(VueMask);
Vue.use(VueAxios, axios);

new Vue({
  router,
  store,
  vuetify,
  render: (h) =>
    h(App, {
      props: {
        mc: tb.dataset.mc,
        si: tb.dataset.si,
      },
    }),
}).$mount("#participant-portal");
