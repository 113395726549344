<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <p class="question-label" v-html="title"></p>
        <p v-html="helperText"></p>
      </v-col>
    </v-row>
    <v-text-field
      v-show="false"
      :value="selectedOptions?.length > 0"
      :rules="$validation.required"
    />
    <v-row v-for="option in orderedOptions" :key="option.value">
      <v-col cols="12" sm="12">
        <v-btn
          rounded
          :color="
            isSelected(option) ? 'darkBlue' : 'orangeBrown'
          "
          @click="toggleSelectedValues(option)"
          :class="[
            'white--text option-select pa-4',
            { selected: isSelected(option) },
          ]"
          width="100%"
        >
          <v-icon
            v-if="isSelected(option)"
            left
          >
            mdi-check-bold
          </v-icon>
          <span class="text-wrap">{{ option.label }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "MultiSelect",
  data() {
    return {
      selectedOptions: [],
    };
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    helperText: {
      type: String,
      required: false,
      default: null,
    },
    preset: {
      type: Array,
      required: false,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.setDefaults();
      },
    },
  },
  computed: {
    orderedOptions() {
      const options = [...this.options];
    
      const index = options.findIndex(option => option.value === "none_of_the_above" || option.value === "noneOfTheAbove");
      if (index !== -1) {
        options.push(options[index]);
        options.splice(index, 1);
      }

      return options;
    },
    isNoneOfTheAboveSelected() {
      return this.selectedOptions.some(option => option.value === "none_of_the_above" || option.value === "noneOfTheAbove");
    },
  },
  methods: {
    ...mapActions("portal", ["setTransitionTo"]),
    isSelected(option) {
      for (let i = 0; i < this.selectedOptions.length; i++) {
        const selectedOption = this.selectedOptions[i];
        if (selectedOption.id === option.id) {
          return true;
        }
      }
      return false;
    },
    toggleSelectedValues(option) {
      if (this.isSelected(option)) {
        this.selectedOptions = this.selectedOptions.filter(
          (selectedOption) => selectedOption.value !== option.value
        );
      } else {
        if (option.value === "none_of_the_above" || option.value === "noneOfTheAbove" || this.isNoneOfTheAboveSelected) {
          this.selectedOptions = [];
        }
        this.selectedOptions.push({id: option.id, value: option.value});
      }
      this.setTransitionTo(option.tt);
      this.$emit("selectOption", {
        id: this.id,
        selected: this.selectedOptions,
      });
    },
    setDefaults() {
      this.selectedOptions = [];

      // If the user has already selected a value, use that value
      this.preset?.forEach(preset => {
        const option = this.options?.find(option => option.id === preset.id) || {}
        if (option.value === preset.value) {
          this.toggleSelectedValues(option);
        }
      });
    },
  },
  mounted() {
    this.setDefaults();
  },
};
</script>
