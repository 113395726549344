import Vue from "vue";
import { getField, updateField } from "vuex-map-fields";
import { SET_ERROR } from "@/store/mutation-types";

export default {
  namespaced: true,
  state: {
    siteLocationId: "",
    engagementId: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    zipcode: "",
    interests: { uuids: [], other: null },
    error: "",
    contactInterests: [],
  },
  getters: {
    getField,
    getSiteLocationId(state) {
      return state.siteLocationId;
    },
  },
  mutations: {
    updateField,
    [SET_ERROR](state, message) {
      state.error = message;
    },
    SET_CONTACT_INTERESTS(state, contactInterests) {
      state.contactInterests = contactInterests;
    },
  },
  actions: {
    setSelectedSite: ({ state }, siteLocationId) => {
      state.siteLocationId = siteLocationId;
    },
    setEngagement: ({ state }, engagementId) => {
      state.engagementId = engagementId;
    },
    setZipcode: ({ state, commit }, zipcode) => {
      return Vue.axios
        .get("/api/validate-zipcode/" + zipcode)
        .then(({ data }) => {
          if (data) {
            state.zipcode = zipcode;

            return zipcode;
          }

          return false;
        })
        .catch((response) => {
          commit(SET_ERROR, response);

          return false;
        });
    },

    // Add the details of the contact along with information pertaining to the
    // contact's experience (e.g. survey question details)
    patchContact({ state, rootState, commit }) {
      // set contact ID
      let contactId = rootState.portal.ci;
      commit(SET_ERROR, "");
      const surveyQuestions = [];

      for (const [, surveyPage] of Object.entries(
        rootState.surveySubmissions.surveyPageHistoryQuestionEntryData
      )) {
        if (Array.isArray(surveyPage)) {
          for (const surveyPageQuestion of surveyPage) {
            surveyQuestions.push(surveyPageQuestion);
          }
        }
      }

      return Vue.axios
        .patch("/api/contacts/" + contactId, {
          data: {
            type: "contact",
            id: contactId,
            attributes: {
              siteLocationId: state.siteLocationId,
              mediaChannelId: rootState.mediaChannel,
              mediaChannelUrl: rootState.mediaChannelUrl,
              engagementId: state.engagementId,
              firstname: state.firstName,
              lastname: state.lastName,
              email: state.email,
              phone: state.phone,
              address: state.zipcode,
              interests: state.interests,
              questions: surveyQuestions,
              source: window.location.href,
            },
          },
        })
        .then((response) => {
          // When a record is successfully updated, a 200 response is given
          const isSuccess = response.status === 200;

          return Promise.resolve([isSuccess]);
        })
        .catch(() => {
          commit(SET_ERROR, "Could not add contact");

          return Promise.resolve([false]);
        });
    },

    /**
     * GET a ContactInterests Collection
     * @param commit
     * @param sortBy
     * @param page
     * @param limit
     * @returns {Promise<boolean>}
     */
    getContactInterests(
      { commit },
      { sortBy = ["name"], page = 1, limit = 1000 } = {}
    ) {
      commit("SET_CONTACT_INTERESTS", []);

      const params = {
        page,
        limit,
      };

      const sort = [];
      sortBy.forEach((fieldSort) => {
        sort.push(fieldSort);
      });
      params.sort = sort.join(",");

      return Vue.axios
        .get("/api/therapeutic-areas", {
          params,
        })
        .then(({ data }) => {
          commit("SET_CONTACT_INTERESTS", data.data);

          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);

          return false;
        });
    },
  },
};
