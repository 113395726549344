<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="pb-0">
        {{ title }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" class="pb-0">
        <v-text-field
          v-model="date"
          outlined
          autofocus
          :label="label"
          :id="dateFieldIdentifier"
          :rules="$validation.dob(this.genericMessages.date_format)"
          placeholder="mm/dd/yyyy"
          :hint="this.genericMessages.date_format"
          v-mask="'##/##/####'"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "DateField",
  data() {
    return {
      date: "",
    };
  },
  props: {
    value: {
      type: String,
      required: false,
      default: "",
    },
    id: {
      type: String,
      required: true,
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    title: {
      type: String,
      required: true,
    },
  },
  watch: {
    date() {
      this.$parent.$emit("selectOption", {
        id: this.id,
        selected: [{id: "dob", value: this.date}],
      });
    },
    value() {
      this.date = this.value;
    },
  },
  computed: {
    ...mapFields("portal", ["genericMessages"]),
    dateFieldIdentifier() {
      return this.id ? this.id : "date-field-" + this._uid;
    },
  },
};
</script>
