<template>
  <div id="participant-portal">
    <v-app>
      <v-main>
        <v-container>
          <v-row justify="center">
            <v-col cols="12" sm="10" :md="mediumWidth" :lg="largeWidth" :xl="extraLargeWidth">
              <component :is="renderedView"></component>
              <!--  Uncomment for local dev.  -->
              <!--  <router-view />-->
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<style>
* {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 18px;
  text-transform: none;
}
#trialblazer {
  overflow-x: unset;
  overflow-y: unset;
  padding: 30px 0;
}
.question-label,
.option-select {
  font-weight: bold;
}
.option-select > .v-btn__content {
  flex: auto;
}
.question-label {
  font-size: 23px;
  color: #001a6f;
}
.option-select {
  white-space: break-spaces;
  height: auto !important;
}
.option-select:hover {
  background-color: #812206;
  /*
  background-color: #812206 !important;
  */
}
.option-select:focus {
  background-color: #001a6f !important;
}
</style>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import Acceptance from "@/views/Acceptance.vue";
import Confirmation from "@/views/Confirmation";
import ContactForm from "@/views/ContactForm";
import Screener from "@/views/Screener.vue";
import Sites from "@/views/Sites";

export default {
  name: "App",
  components: {
    Acceptance,
    Confirmation,
    ContactForm,
    Screener,
    Sites,
  },
  computed: {
    ...mapGetters("portal", {
      renderedView: "getRenderedView",
      contactId: "getContactId",
    }),
    isSitesView() {
      return this.renderedView === "Sites";
    },
    mediumWidth() {
      return this.isSitesView ? 12 : 8;
    },
    largeWidth() {
      return this.isSitesView ? 10 : 8;
    },
    extraLargeWidth() {
      return this.isSitesView ? 8 : 6;
    },
  },
  props: [
    "mc", // Media Channel uuid
    "si", // Survey Identity uuid
  ],
  methods: {
    ...mapActions([
      "setMediaChannel",
      "setMediaChannelUrl",
    ]),
    ...mapActions("portal", [
      "setRenderedView",
      "setScreenerId",
      "start"
    ]),
  },
  beforeMount() {
    this.setRenderedView("acceptance");
  },
  async mounted() {
    this.setMediaChannel(this.mc);
    this.setScreenerId(this.si);
    this.setMediaChannelUrl(window.location.href)
    await this.start();
    window.addEventListener('beforeunload', () => {
      // If user is not on the Confirmation page, then the survey was abandoned
      if (this.renderedView !== "Confirmation") {
        // Send the abandoned survey API request
        return Vue.axios
            .get("/api/portal/abandoned/" + this.contactId)
            .catch(() => {});
      }
    });
  },
};
</script>
