import Vue from "vue";
import { createHelpers, getField, updateField } from "vuex-map-fields";
import {
  SET_SCREENER,
  SNAPSHOT_SURVEY_PAGE_HISTORY,
  POP_SURVEY_PAGE_HISTORY,
  SANITIZE_SURVEY_PAGE_HISTORY,
} from "@/store/mutation-types";


const survey = () => {
  return {
    id: null,

    // If dealing with a survey that should be savable (i.e. we aren't in preview mode),
    // we should have the concept of a survey submission as the saved instance of the survey for
    // the current session
    submissionId: null,
    submissionAccessToken: null,
    identity: null,
    isActive: false,

    // When managing a survey, we can be managing it in the scope of a media channel (a governing body
    // for managing screeners). Account for necessary information about a related media channel for it to
    // be populated when applicable
    targetMediaChannelId: null,
    targetMediaChannelIsActive: 0,

    // When a submission id has not been created yet but we are sending metric data, lets temporarily
    // hold that metric data until submission id is retrieved
    tempMetricData: undefined,
  };
};

const surveyPage = () => {
  return {
    id: null,

    // Track the parent ID of a survey page since this is a necessary detail to have whenever
    // createSurveyPageEntry needs to be used since we need to ensure an entry for the parent is
    // created before we can create an instance of the current page
    parentId: null,
    identity: null,

    // If dealing with a "saved" page for a survey submission, the saved
    // page should come with an entryId
    entryId: null,
    title: null,
    startTime: null,
  };
};

const {
  getSurveyPageQuestionEntriesField,
  updateSurveyPageQuestionEntriesField,
} = createHelpers({
  getterType: "getSurveyPageQuestionEntriesField",
  mutationType: "updateSurveyPageQuestionEntriesField",
});

const defaultState = () => {
  return {
    isPreview: false,
    contactId: false,
    versionId: null,
    progressBarMax: 0,
    progressBarValue: 0,
    survey: survey(),
    surveyMessages: {},
    surveyPage: surveyPage(),
    surveyPageQuestions: {},
    surveyPageQuestionEntries: {},

    // Save a history of which pages the user has been to during this session. This will be used to provide users the
    // ability to go back to what pages they've already been to for the session (i.e. a back button). This is meant to
    // operate like javascript history state where there is only a concept of where the user has been for the current session.
    // If the session is lost, there will be no concept of where the user has been and they'll be starting fresh
    surveyPageHistory: [],

    // Save a history of the questions asked and answers that have been given for survey pages that have been encountered by the user.
    // Like surveyPageHistory, this data will need to be sanitized to only consist of the pages that the user has encountered in his/her current
    // path. We maintain this data so it can be referenced as the completed survey once the survey submission process has completed.
    // IMPORTANT: If post processing is ever applied to question entry data, the data maintained for history should be recorded from the response data
    // provided when a submission page is saved. This way we can ensure any post processing that may happen on the server to entry data is reflected.
    // This is not done by default since it introduces additional concerns that are unnecessary to account for unless post processing is introduced
    surveyPageHistoryQuestionEntryData: {},
  };
};

export default {
  namespaced: true,
  state: defaultState(),
  getters: {
    getField,
    getSurveyPageQuestionEntriesField,
    progressBarData: (state) => {
      return {
        value: Math.floor(
          (state.progressBarValue / state.progressBarMax) * 100
        ),
      };
    },
    surveyIsPreview(state) {
      return state.isPreview;
    },
    survey(state) {
      return state.survey;
    },
    surveyPage(state) {
      return state.surveyPage;
    },
    surveyPageQuestions(state) {
      return state.surveyPageQuestions;
    },
  },
  mutations: {
    updateField,
    updateSurveyPageQuestionEntriesField,
    RESET_STATE(state) {
      Object.assign(state, defaultState());
    },
    RESET_PAGE_STATE(state) {
      state.surveyPage = surveyPage();
      state.surveyPageQuestions = {};
      state.surveyPageQuestionEntries = {};
    },

    [SET_SCREENER](state, survey) {
      state.survey = {
        id: survey.id,
        surveyStartTime: 0,
        identity: survey.attributes.identity,
        isActive: survey.attributes.isActive,
      };
    },

    // For the current survey page and current question/entry data, record details about
    // the history of these items. This should be utilized before directing to a different survey page.
    [SNAPSHOT_SURVEY_PAGE_HISTORY](state) {
      state.surveyPageHistory = state.surveyPageHistory.concat([
        { ...state.surveyPage },
      ]);

      const surveyPageHistoryQuestionEntryData = [];

      for (const [key, question] of Object.entries(state.surveyPageQuestions)) {
        surveyPageHistoryQuestionEntryData.push({
          question_id: key,
          question_text: question.title,
          question_answer: state.surveyPageQuestionEntries[key] ?? null,
        });
      }

      state.surveyPageHistoryQuestionEntryData = Object.assign(
        {},
        state.surveyPageHistoryQuestionEntryData,
        { [state.surveyPage.identity]: surveyPageHistoryQuestionEntryData }
      );
    },

    // Remove the last survey page from survey page history. This is useful for the concept of going "back"
    // to a previous survey page as the last page that was added will be the one a user goes "back" to, and
    // it should no longer be in history as a result
    [POP_SURVEY_PAGE_HISTORY](state) {
      const targetPageHistoryIndex = state.surveyPageHistory.length - 1;

      // If going back, we also need remove the saved history question data for the history item we are popping from
      // history
      if (
        Object.hasOwn(
          state.surveyPageHistoryQuestionEntryData,
          state.surveyPageHistory[targetPageHistoryIndex].identity
        )
      ) {
        Vue.delete(
          state.surveyPageHistoryQuestionEntryData,
          state.surveyPageHistory[targetPageHistoryIndex].identity
        );
      }

      state.surveyPageHistory.splice(targetPageHistoryIndex, 1);
    },

    // Ensure that the current state of page history can be considered "valid"
    [SANITIZE_SURVEY_PAGE_HISTORY](state) {
      if (state.surveyPage.identity !== null) {
        // We want to ensure that if a survey directs a user to a current survey page that has already existed in
        // the history of survey pages, that we clear out all pages that are "future" of that page. We don't want to
        // persist data that would indicate a user reached a page that we can't guarantee that he/she actually made it to.
        const currentPageIndex = state.surveyPageHistory.findIndex(
          (element) => element.identity == state.surveyPage.identity
        );

        if (currentPageIndex !== -1) {
          // If the current page exists in history, we want to consider all page elements that are indexed before that page to be the only ones still in history.
          // We don't keep the current page in history, so that should be removed, and all "future" pages that came after it aren't guaranteed to be in the current
          // path anymore, so we want to consider them irrelevant until a user makes them relevant again

          // Identify all pages that will be removed by slicing history, and remove their related history question entry data
          for (
            let pageIndex = currentPageIndex;
            pageIndex < state.surveyPageHistory.length;
            pageIndex++
          ) {
            if (
              Object.hasOwn(
                state.surveyPageHistoryQuestionEntryData,
                state.surveyPageHistory[pageIndex].identity
              )
            ) {
              Vue.delete(
                state.surveyPageHistoryQuestionEntryData,
                state.surveyPageHistory[pageIndex].identity
              );
            }
          }

          state.surveyPageHistory = state.surveyPageHistory.slice(
            0,
            currentPageIndex
          );
        }
      } else {
        // If sanitizing history, and there is no valid way to consider the current page, purge all history since we can't determine
        // if saved data should be considered valid or not, and we want to err on the side of not keeping data if we can't guarantee it should
        // be there. This shouldn't happen, and this is just a logical precaution
        state.surveyPageHistory = [];
      }
    },
  },
  actions: {
    refreshState({ commit }) {
      commit("RESET_STATE");
    },

    snapshotSurveyPageHistory({ commit }) {
      commit(SNAPSHOT_SURVEY_PAGE_HISTORY);
    },
  },
};
