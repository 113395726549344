import dayjs from "@/plugins/dayjs";

const required = v => !!v || v === 0 || 'This field is required';
const float = v => (v && /^\d+(\.\d+)?$/.test(v)) || !v || 'Enter a valid number';
const positiveInteger = v => (v && /^\d+$/.test(v)) || !v || 'Enter a positive number';
const numeric = v => (v && /^[-]?\d+$/.test(v)) || !v || 'Must only contain numbers';

const rules = {
  required: [required],
  requiredCheckbox: error => [v => v === true || (error || 'Must accept to continue')],
  requiredCheckboxes: error => [v => (v && v.length > 0) || (error || 'At least one option is required')],
  numeric: [numeric],
  positiveInteger: [positiveInteger],
  float: [float],
  required255Max: error => [
    v => (
      v
      && v?.length < 256
    ) || (error || 'This field is required')
  ],
  name: error => [
    v => (
      v?.length > 1
      && v?.length < 256
    ) || (error || 'This field is required')
  ],
  email: error => [
    v => (
      v?.length > 3
      && /.+@.+\..+/.test(v)
    ) || (error || 'Must be a valid email format')
  ],
  usPhone: error => [
    v => (
      v?.length === 12
      && /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(v)
    ) || (error || 'Must match the format 000-000-0000')
  ],
  zipCode: error => [
    v => (
      v?.length > 1
      && v?.length < 256
    ) || (error || 'This field is required')
  ],
  date: error => [
    v => (
      v?.length === 10
      && dayjs(v, ['MM/DD/YYYY'], 'es', true).isValid()
    ) || (error || 'Must match the format MM/DD/YYYY')
  ],
  dob: error => [
    v => (
      v?.length === 10
      && dayjs(v, ['MM/DD/YYYY'], 'es', true).isValid()
      && dayjs().diff(dayjs(v), 'day') >= 0
      && dayjs().diff(dayjs(v), 'year') < 150
    ) || (error || 'Must match the format MM/DD/YYYY')
  ],
}

export default {
  install(Vue) {
    if (this.installed) return
    Vue.prototype.$validation = rules
    this.installed = true
  },
}