import { getField } from "vuex-map-fields";

export default {
  getField,
  getMediaChannelUrl: (state) => {
    return state.mediaChannelUrl;
  },
  getRenderedView: (state) => {
    return state.renderedView;
  },
  getScreenerIdentity(state) {
    return state.screenerIdentity;
  },
};
