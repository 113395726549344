<template>
  <v-form
    v-if="!isLoading"
    v-model="formValid"
    @submit.prevent="submit"
  >
    <v-container>
      <v-row>
        <v-col cols="12" class="px-0">
          <p
            class="contact-terms"
            v-html="contentFormMessage"
          />
        </v-col>
      </v-row>
      <v-row>
        <p class="font-weight-bold" v-html="genericMessages.contact_details"></p>
      </v-row>
      <v-row>
        <v-col cols="12" class="px-0">
          <v-text-field
            dense
            outlined
            autofocus
            :label="genericMessages.first_name"
            v-model="firstName"
            :rules="$validation.name(genericMessages.required_field)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="px-0">
          <v-text-field
            dense
            outlined
            :label="genericMessages.last_name"
            v-model="lastName"
            :rules="$validation.name(genericMessages.required_field)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="px-0">
          <v-text-field
            dense
            outlined
            :label="genericMessages.email"
            :hint="genericMessages.email_format"
            v-model="email"
            :rules="$validation.email(genericMessages.email_format)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="px-0">
          <v-text-field
            dense
            outlined
            :label="genericMessages.phone_number"
            v-model="phone"
            :hint="genericMessages.phone_number_format"
            v-mask="'###-###-####'"
            :rules="$validation.usPhone(genericMessages.phone_number_format)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="px-0">
          <v-text-field
            dense
            outlined
            :label="genericMessages.zip_code"
            v-model="zipcode"
            :hint="genericMessages.invalid_zipcode"
            :rules="$validation.zipCode(genericMessages.invalid_zipcode)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="px-0">
          <p
            class="contact-consent"
            v-html="consentMessage"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="px-0">
          <v-btn
            rounded
            color="orangeBrown"
            class="white--text pa-7"
            type="submit"
            :disabled="!formValid"
          >
            {{ genericMessages.submit }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<style>
.v-messages {
  min-height: 0;
}
.v-messages__message {
  line-height: 18px;
  font-size: 12px;
}
.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
.v-input__slot {
  margin-bottom: 0;
}
</style>
<script>
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "ContactForm",
  data() {
    return {
      formValid: false,
      isLoading: false,
      isSubmitting: false,
    };
  },
  computed: {
    ...mapFields("contact", [
      "firstName",
      "lastName",
      "email",
      "phone",
      "zipcode",
    ]),
    ...mapFields("portal", [
      "genericMessages",
      "screenerMessages",
      "page",
      "screener",
    ]),
    ...mapGetters("contact", {
      siteLocationId: "getSiteLocationId",
    }),
    getScreenMessage() {
      const status = [
        {
          check: !this.screener.isActive,
          message: this.screenerMessages.notActive,
        },
        {
          check: this.page.isQualified === false,
          message: this.screenerMessages.notQualified,
        },
        {
          check: !!this.siteLocationId,
          message: this.screenerMessages.trialSiteSelected,
        },
      ];

      return status.find(({check}) => check)?.message || this.screenerMessages.noTrialSites;
    },

    consentMessage() {
      return this.getScreenMessage.consentMessage;
    },

    contentFormMessage() {
      return this.getScreenMessage.contactFormMessage;
    },
  },
  methods: {
    ...mapActions("contact", ["patchContact"]),
    ...mapActions("portal", ["setRenderedView"]),
    submit() {
      if (this.isSubmitting) {
        return;
      }

      this.isSubmitting = true;
      this.patchContact().then(([success]) => {
        if (success) {
          // If successfully added contact information, emit an event to indicate this to a parent
          // component/view. Transition the form to loading so that it can't be interacted with anymore
          // since the data from the form has officially been saved
          this.isLoading = true;
          this.$emit("contactInformationSaved");
          this.setRenderedView("confirmation");
        }

        this.isSubmitting = false;
      });
    },
  },
  mounted() {
    document
      .getElementById("trialblazer")
      ?.scrollIntoView({ behavior: "smooth" });
  },
};
</script>
