<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" class="px-0">
        <v-toolbar dense max-height="50px" elevation="0">
          <v-row>
            <v-col cols="12" class="px-0">
              <v-progress-linear
                background-color="#F4EDE6"
                color="orangeBrown"
                height="7"
                rounded
                :value="progressBarData.value"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" class="px-0">
        <v-form
          v-model="formValid"
          @submit.prevent="submitForm"
        >
          <v-row>
            <v-col cols="12" class="pa-0">
              <template v-for="(question) in page.questions">
                <component
                  :id="question.id"
                  :is="question.component"
                  :key="question.id"
                  :title="question.title"
                  :helperText="question.helper_text"
                  :options="question.options"
                  :preset="page.preset"
                  @selectOption="updateSelectedOptions"
                />
              </template>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <next-button :isDisabled="!formValid" />
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import Birthdate from "@/components/screeners/questions/Birthdate.vue";
import MultiSelect from "@/components/screeners/questions/MultiSelect.vue";
import NextButton from "@/components/NextButton.vue";
import Select from "@/components/screeners/questions/Select.vue";

export default {
  name: "Screener",
  components: { Birthdate, MultiSelect, NextButton, Select },
  data() {
    return {
      formValid: false,
      isSubmitting: false,
      selectedOptions: [],
    };
  },
  watch: {
    page: {
      deep: true,
      handler() {
        this.selectedOptions = [];
      },
    },
  },
  computed: {
    ...mapFields("portal", ["genericMessages"]),
    ...mapGetters("portal", {
      page: "getPage",
      progressBarData: "getProgressBarData",
      transitionTo: "getTransitionTo",
      surveyHistory: "getSurveyHistory",
    }),
  },
  methods: {
    ...mapActions("portal", ["next", "back", "start", "setRenderedView"]),
    submitForm() {
      if (this.isSubmitting) return;
      this.isSubmitting = true;

      // Deconstruct the selected options into a flat array
      const options = [];
      this.selectedOptions.forEach(question => {
        question.selected?.forEach(selected => {
          options.push(selected);
        });
      });

      this.next({
        tt: this.transitionTo,
        options,
        questions: this.page.questions,
      });

      this.isSubmitting = false;
      this.setRenderedView("next");

      document
        .getElementById("trialblazer")
        ?.scrollIntoView({ behavior: "smooth" });
    },
    updateSelectedOptions(payload) {
      // Remove only the selected option from the array
      this.selectedOptions.forEach((option, index) => {
        if (option.id === payload.id) {
          this.selectedOptions.splice(index, 1);
        }
      });

      // Append the new selected option to the array
      this.selectedOptions.push(payload);
    },
  },
};
</script>

<style lang="scss"></style>