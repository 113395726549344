<template>
  <date-field
    v-model="value"
    :id="id"
    :isRequired="true"
    :title="title"
  />
</template>

<script>
import DateField from "@/components/DateField";

export default {
  name: "Birthdate",
  components: { DateField },
  data() {
    return {
      value: "",
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    preset: {
      type: Array,
      required: false,
      default: null,
    },
  },
  mounted() {
    // If the user has already entered a birthdate, use that value
    this.value = this.preset?.find(preset => preset.id === "dob")?.value || "";

    document
      .getElementById("trialblazer")
      ?.scrollIntoView({ behavior: "smooth" });
  },
};
</script>
